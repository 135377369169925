import React from "react"

const SoundWave = () => {
    return (
        <div className="boxContainerAudio">
            <div className="boxAudio box1Audio"></div>
            <div className="boxAudio box2Audio"></div>
            <div className="boxAudio box3Audio"></div>
            <div className="boxAudio box4Audio"></div>
            <div className="boxAudio box5Audio"></div>
        </div>
    )
}

export default SoundWave