import { GptHandler } from './fetchClient';

export class GptAPI {
  /**
   * @desc Api methods to speak with the BE
   */
  static async sendWizardInfo (payload) {
    const res = await GptHandler('ask_questions/', payload);
    const response = await res.json()
    return response
  }

  static async createIdeasGPT (payload) {
    const res = await GptHandler('create_ideas/', payload);
    const response = await res.json()
    return response
  }

  static async updateItineraryGPT (payload) {
    const res = await GptHandler('update_itinerary/', payload);
    const response = await res.json()
    return response
  }
  
  static async getTheWeather (payload) {
    const res = await GptHandler('weather/', payload);
    const response = await res.json()
    return response
  }

  static async getPlaceDetails (place_id, session_id) {
    const res = await GptHandler(`place/${place_id}?session_id=${session_id}`);
    const response = await res.json()
    return response
  }

  static async getPlaceDescription (place_id, session_id) {
    const res = await GptHandler(`place_dup/${place_id}?session_id=${session_id}`);
    const response = await res.json()
    return response
  }

  static async getVoiceFromText (text) {
    const res = await GptHandler(`text-to-speech`, {
      text: text,
      model_id: "eleven_multilingual_v2",
      stability: 0.3,
      similarity_boost: 0.75,
      style: 0.4,
      use_speaker_boost: true,
      output_format: "mp3_44100_128"
    });
    const response = await res.json()
    return response
  }

  static async sendChatData (payload) {
    try {
      const res = await GptHandler('chat/', payload);
      const response = await res.json()
      return response
    } catch (error) {
      return error
    }
  }
}
