import React, { useEffect, useState } from "react";
import zia from '../assets/images/zia-tutorial.svg'
import tav from '../assets/images/avatar.svg'
import play from '../assets/images/play.svg'
import clsx from "clsx";
import cut from '../assets/images/cutleries.svg'
import { useLayout } from "../contexts/layoutContext";
import { OPTIONS } from "../utils/CONSTANTS";
import SoundWave from "./SoundWave";
import { useChat } from "../contexts/chatContext";
import { useLS } from "../contexts/LsContext";

const SingleChat = ({
    data, 
    source,
    agent, 
    text, 
    short_text,
    options, 
    confirmation_message, 
    isAudio, 
    index,
}) => {

    const layoutData = useLayout() || {};
    const {setPlaceDetail} = layoutData;
    
    const chatData = useChat() || {};
    const {audioElement, setAudioElement, answerWithVoice, autoPlay} = chatData;
    const [isThisPlaying, setIsThisPlaying] = useState(false);
    const [isThisLoading, setIsThisLoading] = useState(false);
    const [thisAudioUrl, setThisAudioUrl] = useState(null);

    const LSData = useLS() || {};
    const { getFromLS } = LSData;

    useEffect(() => {
        console.log('autoPlayautoPlayautoPlay', data);
        if (autoPlay && agent === 'bot') {
            // Stop any existing audio first
            if (audioElement) {
                audioElement.pause();
                audioElement.src = '';
            }
            // Small timeout to ensure cleanup is complete
            setTimeout(() => {
                playSingleAudio();
            }, 100);
        }
    }, [data]);

    const playSingleAudio = async () => {
        try {
            // Stop any existing audio
            if (audioElement) {
                audioElement.pause();
                audioElement.src = '';
            }

            setIsThisLoading(true);
            
            // Get chat history from localStorage
            const chatHistory = await getFromLS('chatLS');
            if (!chatHistory) return;
            
            // Find the current message in the chat history
            const currentMessage = chatHistory.find(msg => 
                msg.text === text && msg.agent === agent
            );

            console.log('currentMessage', currentMessage);

            // Use short_text from localStorage if available, fallback to current text
            const textToSpeak = currentMessage?.short_text || text;
            const url = await answerWithVoice(textToSpeak);
            if (!url) return;

            const newAudio = new Audio(url);
            setAudioElement(newAudio);
            setThisAudioUrl(url);

            // Add ended event listener
            newAudio.addEventListener('ended', () => {
                setIsThisPlaying(false);
            });

            // Play the audio
            await newAudio.play();
            setIsThisPlaying(true);
            setIsThisLoading(false);

        } catch (error) {
            console.error('Error playing audio:', error);
            setIsThisLoading(false);
            setIsThisPlaying(false);
        }
    };

    // Cleanup on unmount
    useEffect(() => {
        return () => {
            if (audioElement) {
                audioElement.pause();
                audioElement.src = '';
            }
        };
    }, []);

    return (    
        <div className={clsx(agent !== 'bot' && 'bg-white border-b-[1px] border-b-light-grey', "SingleChat")}>
            <div className="p-4 flex items-center gap-3">
                <div className="w-[10%]">
                    {agent === 'bot' && <img className="object-cover rounded-full overflow-hidden h-12" src={zia} />} 
                </div>
                <div className={clsx("w-[80%]")}>
                    <p className={clsx(agent === 'bot' ? 'text-left' : 'text-right', "text-sm px-2")}>
                        {text}
                        {isAudio && isThisPlaying && (
                            <div className="inline-block ml-2">
                                <SoundWave />
                            </div>
                        )}
                    </p>
                    {agent === 'bot' && source && <p className="text-[10px] px-2 opacity-40">Fonte: {source}</p>}
                </div>
                <div className="w-[10%]">
                    {agent !== 'bot' && <img className="rounded-full overflow-hidden h-12" src={tav} />} 
                    
                    {/* AUDIO CONTROLS */}
                    {agent === 'bot' && (
                        <>
                            {isThisLoading ? (
                                <div className="loadingAudio w-[60px] flex items-center justify-end pr-4">
                                    <svg id="dots" width="40px" height="16px" viewBox="0 0 132 58" version="1.1" xmlns="http://www.w3.org/2000/svg">
                                        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                            <g id="dots" fill="" className="fill-orange">
                                                <circle id="dot1" cx="25" cy="30" r="13"></circle>
                                                <circle id="dot2" cx="65" cy="30" r="13"></circle>
                                                <circle id="dot3" cx="105" cy="30" r="13"></circle>
                                            </g>
                                        </g>
                                    </svg>
                                </div>
                            ) : isThisPlaying ? (
                                <div className="w-full flex items-center">
                                    <SoundWave />
                                </div>
                            ) : (
                                <div onClick={playSingleAudio} className="flex w-[30px] h-[30px] items-center gap-1 bg-orange rounded-full inline-flex ml-2 justify-center cursor-pointer">
                                    <img src={play} className="w-3 ml-[2px]"/>
                                </div>
                            )}
                        </>
                    )}
                </div>
            </div>

            {/* Options Grid */}
            {options && options.length > 0 && (
                options.map((el) => (

                    <div className="w-full" key={el.name}>
                        <p className="text-lg px-4 text-center mb-2 text-orange">{el?.date}</p>
                        {el?.activities && el?.activities.length>0 && (
                            <div className="options-grid grid grid-cols-2 gap-3 px-4 pb-4">
                            {
                                el?.activities.map((el) => (
                                    <div className={clsx(`w-full rounded-xl p-2 bg-${OPTIONS[el?.type]?.color}`)}>
                                        <img src={OPTIONS[el?.type]?.icon} className="block mx-auto my-2 w-8"/>
                                        <div className='text text-center'>
                                            <p className='text-xs text-white'>{el?.name}</p>
                                            <p className='text-[10px] opacity-70 text-white'>{el?.location}</p>
                                        </div>
                                        <button 
                                            className={`w-full block rounded-full bg-white border-[1px] border-${OPTIONS[el?.type]?.color} text-center my-3 mb-2 text-sm py-1`} 
                                            onClick={() => setPlaceDetail(el?.placeId)}
                                        >
                                            Informazioni
                                        </button>
                                    </div>
                            ))
                            }
                            </div>
                        )}
                    </div>
                ))
            )}

            {/* Confirmation Message */}
            {confirmation_message && (
                <div className="p-4 flex items-center gap-3">
                    <div className="w-[10%]"></div>
                    <div className={clsx("w-[80%]")}>
                        <p className={clsx(agent === 'bot' ? 'text-left' : 'text-right', "text-sm px-2")}>
                            {confirmation_message}
                        </p>
                    </div>
                </div>
            )}
        </div>
    )
}

export default SingleChat