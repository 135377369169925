const postClient = (endpoint, payload = null) => {
    const url = `${process.env.GATSBY_BE_URL}/${endpoint}`;
    return fetch(url, {
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        mode: "cors",
        headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*"
        },
        body:JSON.stringify(payload)
    })
}

const post11Client = (resp) => {
    const url = `${process.env.GATBSY_FE_11LABS_URL}/${process.env.GATBSY_FE_11LABS_VOICE}`;
    return fetch(url, {
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        headers: {
            'Content-Type': 'application/json',
            'xi-api-key': process.env.GATBSY_FE_11LABS_API,
          },
        responseType: 'arraybuffer',
        // body:JSON.stringify(payload)
        body:JSON.stringify({
            
            text: 'ciao' || resp.toString(),
            model_id: "eleven_multilingual_v2",
            voice_settings: {
                stability: 0.3,
                similarity_boost: 0.75,
                style: 0.4,
                use_speaker_boost: true
            }
        })
    })
}

const getClient = (endpoint) => {
    const url = `${process.env.GATSBY_BE_URL}/${endpoint}`;
    return fetch(url)
}

export const GptHandler = async (endpoint, payload) => {
    try {  
        return await payload ? postClient(endpoint, payload) : getClient(endpoint);
    } catch (er) {
        console.error('error gpt ->', er);
        throw new Error(er)
    }
}

export const LabsHandler = async (payload) => {
    try {  
        return await post11Client(payload);
    } catch (er) {
        console.error('error voice ->', er);
        return {error: er}
    }
}