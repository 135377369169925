import React, { useState, useEffect, useRef } from "react";
import { useChat } from "../contexts/chatContext";
import clsx from "clsx";
import Close from "./icons/iconsClose";
import SingleChat from "./singleChat";
import LoadingChat from "./loadingChat";
import play from '../assets/images/play.svg'
import send from '../assets/images/send.svg'
import VoiceBallonDisclaimer from "./VoiceBallonDisclaimer";
import { useLS } from "../contexts/LsContext";
import { LOCAL_STORAGE_KEYS } from "../utils/CONSTANTS";

const ChatView = ({ show, setShow, setInputValue, inputValue, handleSubmit, inputRef, endOfContainerRef }) => {
    const chatData = useChat() || {};
    const { 
        chatList, 
        simpleLoading, 
        isAudio, 
        setIsAudio, 
        audioUrl, 
        setAudioUrl, 
        isPlaying, 
        setIsPlaying, 
        isLoadingAudio,
        showBalloon,
        autoPlay,
        setAutoPlay
    } = chatData;

    const LSData = useLS() || {};
    const {getFromLS, setToLS, removeAllDataFromLS} = LSData;

    const [modalHeight, setModalHeight] = useState(null);

    const audioRef = useRef();

    useEffect(() => {
        const getChatLS = async () => {
            const chatLS = await getFromLS(LOCAL_STORAGE_KEYS.chat);
            if (!chatLS) {
                await setToLS(LOCAL_STORAGE_KEYS.chat, chatList); 
            }
        };
        getChatLS();
      }, []);

    useEffect(() => {
        if (audioRef.current && isPlaying) {
          audioRef.current.play();
        }
      }, [audioUrl, isPlaying]);

      const toggleAudio = () => {
        setIsAudio(!isAudio);
        if (!isAudio) {
            setAudioUrl(false)
        }
      }


    const handleAudioEnded = () => {
        setIsPlaying(false);
      };
    
      // Function to handle replay button click
      const handleReplay = () => {
        if (audioRef.current) {
          audioRef.current.currentTime = 0;
          audioRef.current.play();
          setIsPlaying(true);
        }
      };

    const scrollToEndOfChat = () => {
        const endOfChat = document.getElementById('endOfChat');
        endOfChat.scrollIntoView({
            behavior: "smooth",
        });
    }

    useEffect(() => {
        function isKeyboardOpen() {
            const { innerHeight, innerWidth } = window;
            const vh = Math.min(innerHeight, innerWidth);
            // Assuming keyboard height threshold as 100px (adjust as needed)
            return vh < innerHeight;
        }

        function calculateModalHeight() {
            if (show && isKeyboardOpen()) {
                const { innerHeight } = window;
                // Assuming you have a fixed header of height 90px
                const fixedHeaderHeight = 64;
                const availableHeight = innerHeight - fixedHeaderHeight;
                setModalHeight(availableHeight);
                document.body.classList.add('overflow-hidden');
            } else {
                setModalHeight(null);
                document.body.classList.remove('overflow-hidden');
            }
        }

        // Call the function when show or window size changes
        setInputValue('')
        calculateModalHeight();
        window.addEventListener('resize', calculateModalHeight);
        return () => window.removeEventListener('resize', calculateModalHeight);
    }, [show]);

    useEffect(() => {
        scrollToEndOfChat();
    }, [chatList?.length])

    const sendInput = async () => {
        if (!inputValue.trim()) return;
        
        // Blur the input
        inputRef.current.blur();
        
        // Submit the chat
        await handleSubmit({
            preventDefault: () => {}  // Mock event object
        });
        
        // Focus back on input after a small delay
        setTimeout(() => {
            inputRef.current.focus();
        }, 100);
    };

    return (
        <div
            className={clsx("overflow-scroll chatView w-full shadow-lg bg-light-grey fixed right-0 transition-all z-30 lg:pb-12", !show ? 'top-[100vh]' : 'top-[63px]')}
            style={{height: modalHeight ? modalHeight : '100vh'}}
        >
            {show && (
                <>
                    <button onClick={() => setShow(false)} className="block p-3 fixed top-[85px] right-[15px] shadow-md h-10 w-10 rounded-full flex items-center bg-white">
                        <Close className="cursor-pointer" fill="fill-orange" />
                    </button>
                    <div className="fixed top-[85px] left-3 flex items-center bg-white px-3 py-2 rounded-full shadow-md h-[40px]">
                        <input
                            type="checkbox"
                            checked={autoPlay}
                            onChange={(e) => setAutoPlay(e.target.checked)}
                            className="mr-2"
                        />
                        <span className="text-sm">Autoplay</span>
                    </div>
                </>
            )}
            {/* <button onClick={handleReplay}>Riascolta</button> */}
            <div className="pt-16 pb-32">
                {chatList && chatList.map((el, index) => {
                    return (
                        <SingleChat
                            isAudio={isAudio} 
                            isPlaying={isPlaying} 
                            isLoadingAudio={isLoadingAudio} 
                            data={el} 
                            key={index} 
                            agent={el?.agent} 
                            source={el?.source} 
                            text={el?.text} 
                            short_text={el?.short_text} 
                            options={el?.options} 
                            confirmation_message={el?.confirmation_message} 
                        />
                    )
                })}
                {simpleLoading && <LoadingChat key={new Date().toISOString()} />}
            </div>
            <audio
                ref={audioRef}
                src={audioUrl}
                onEnded={handleAudioEnded}
            >
                Il tuo browser non supporta l'elemento audio.
            </audio>


            {show && (    
                <div className="w-full px-4 fixed bottom-10 rounded-full z-30 left-0">
                    <div className="relative">
                        {/* {showBalloon && <VoiceBallonDisclaimer />} */}
                        <div className="absolute top-[130px] right-[9px] flex items-center">
                            <input
                                type="checkbox"
                                checked={autoPlay}
                                onChange={(e) => setAutoPlay(e.target.checked)}
                                className="mr-2"
                            />
                            <span className="text-sm">Autoplay</span>
                        </div>
                        <form className="relative" onSubmit={(e) => handleSubmit(e)}>
                            <input 
                                disabled={simpleLoading} 
                                onFocus={scrollToEndOfChat} 
                                ref={inputRef} 
                                value={inputValue} 
                                onChange={(e) => setInputValue(e.target.value)} 
                                className="rounded-full px-2 h-12 px-4 w-full shadow-lg mainChatInput pr-[84px] border-[1px] border-[#cccccc]" 
                                type="text" />
                        </form>
                        
                        
                        
                        <button disabled={simpleLoading} onClick={sendInput} className={clsx("flex items-center absolute top-[9px] right-[12px] w-[30px] h-[30px] rounded-full p-1 rotate-[-34deg] -mt-[2px] buttonAudio cursor-pointer")}>
                            <img className="ml-[2px]" src={send} />
                        </button>
                        
                    </div>
                </div>
            )}
            <div ref={endOfContainerRef} id="endOfChat"></div>
        </div>
    )
}

export default ChatView